import { useEffect } from "react";
import CanvasPart from "./Components/CanvasPart/CanvasPart";
import DesignPanel from "./Components/DesignPanel/DesignPanel";
import DesignPanelToggler from "./Components/DesignPanelToggler/DesignPanelToggler";
import HomeContextProvider from "./Context/HomeContext";
import LoadingMain from "./Components/LoadingMain/LoadingMain";
import dataClient from "../../assets/Json/dataClient.json";

export default function HomePage() {
  useEffect(() => {
    const fonts = dataClient?.design?.text?.textFont;

    const generateFontFaces = (fontArray) => {
      return fontArray
        .map(
          (font) => `
          @font-face {
            font-family: '${font.name}';
            src: url(${font.url});
          }
        `
        )
        .join("");
    };

    const applyDynamicFonts = (fontArray) => {
      const style = document.createElement("style");
      style.innerHTML = generateFontFaces(fontArray);
      document.head.appendChild(style);
    };

    applyDynamicFonts(fonts);
  }, []);

  return (
    <>
      <main className="Home">
        <div className="Home-container ">
          <HomeContextProvider>
            <section className="ShowPart ">
              <CanvasPart />
            </section>

            <section className="DesignPanel row">
              <DesignPanel />
            </section>
            <LoadingMain />
          </HomeContextProvider>
        </div>
      </main>
    </>
  );
}
