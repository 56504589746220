import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import dataClient from "../../../../../../../assets/Json/dataClient.json";

export default function Quabtity() {
  const { quantity, setQuantity } = useContext(HomeContext);

  const [data, setData] = useState({});

  useEffect(() => {
    setData(dataClient);
  }, []);

  return (
    <>
      <section className="Rose-quantity">
        <div className="Rose-quantity-title">
          <span>Quantity</span>
        </div>
        <div className="Rose-quantity-list">
          {data.design?.packaging?.quantity.map((q) => {
            return (
              <div
                className="Rose-quantity-list-item"
                key={q.id}
                onClick={() => {
                  setQuantity({
                    item: q.item,
                    number: q.number,
                  });
                }}
              >
                <div
                  className="Rose-quantity-list-item-image"
                  style={{
                    border:
                      quantity.item === q.item
                        ? "2px solid #000000"
                        : "1px solid #393C41",
                  }}
                >
                  <img src={q.image} alt={q.item} />
                </div>
                <p
                  className="Rose-quantity-list-item-text"
                  style={{ fontWeight: quantity.item === q.item ? "700" : "400" }}
                >
                  {q.title}
                </p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}
