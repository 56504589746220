import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import clientData from "../../../../../../../assets/Json/dataClient.json";

export default function AddClipart() {
  const { setDesignPanelMenu, cliPart, setCliPart, category, setCategory } =
    useContext(HomeContext);

  const [listItem, setListItem] = useState();

  useEffect(() => {
    let list;
    if (category === "All") {
      // ترکیب تمام دسته‌بندی‌ها در یک آرایه‌ی مسطح
      list = clientData?.design?.clipart?.flatMap((item) => item.fileUrl);
    } else {
      list =
        clientData?.design?.clipart?.find((item) => item.category == category)
          ?.fileUrl || [];
    }

    setListItem(list);
  }, [category]);

  const handleClipart = async (name, file) => {
    await setCliPart(null);
    await setCliPart({
      name: name,
      file: file,
    });
  };

  return (
    <>
      <div className="AddClipart">
        <div className="AddClipart-container">
          <div className="AddClipart-title">
            <span>Add Clipart</span>
          </div>

          <div className="AddClipart-content">
            <div className="New-Clipart">
              <div className="New-Clipart-category">
                <label className="New-Clipart-category-title">
                  Choose Category
                </label>
                <select
                  className="New-Clipart-category-list"
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setCliPart(null);
                  }}
                >
                  <option value="All">All</option>
                  {clientData?.design?.clipart?.map((c, index) => {
                    return (
                      <option key={index} value={c.category}>
                        {c.category}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="New-Clipart-list row">
                {listItem?.map((i) => {
                  console.log("listItem>>", listItem);
                  return (
                    <section
                      className="New-Clipart-list-item col-3"
                      style={{
                        border:
                          cliPart?.name === i.name ? "2px solid #B04953" : null,
                        backgroundColor:
                          i.name === cliPart?.name ? "#B0495340" : null,
                      }}
                    >
                      <div
                        className="New-Clipart-list-item-image"
                        onClick={() => {
                          handleClipart(i.name, i.file);
                        }}
                      >
                        <img src={i.file} alt={i.name} />
                      </div>
                      <div
                        className="New-Clipart-list-item-close"
                        style={{
                          display: cliPart?.name === i.name ? "inline" : "none",
                        }}
                        onClick={() => {
                          setCliPart(null);
                        }}
                      >
                        <i className="bi bi-x-lg"></i>
                      </div>
                    </section>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <button className="confirmBtn">Confirm</button> */}
        </div>
      </div>
    </>
  );
}
