import { useContext, useEffect, useState } from "react";
import Price from "./Price/Price";
import { HomeContext } from "../../Context/HomeContext";
import DesignRose from "./DesignRose/DesignRose";
import Packaging from "./Packaging/Packaging";
import dataClient from "../../../../assets/Json/dataClient.json";
import DesignPanelToggler from "../DesignPanelToggler/DesignPanelToggler";

export default function DesignPanel() {
  const [roseColor, setRoseColor, material] = useState("blu11");

  const {
    setColorRoseSelected,
    setColorCode,
    setBaseColorCode,
    roseType,
    setRoseType,
    setMaterial,
    activeSection,
    setActiveSection,
    sectionRefs,
  } = useContext(HomeContext);

  function RoseColor({ color, isActive, onClick }) {
    return (
      <div
        style={{
          border: `${isActive ? "2px" : "1px"} solid ${
            isActive ? "#000000" : color.hex
          }`,
        }}
        onClick={onClick}
      >
        <div
          className="Rose-color-item"
          style={{
            backgroundColor: color.hex,
          }}
        />
        <i
          className="bi bi-check-lg Rose-color-check"
          style={{
            color: color.check,
            display: isActive ? "block" : "none",
          }}
        ></i>
      </div>
    );
  }

  const handleRoseColorChange = (color) => {
    setRoseColor(color.code);
    setColorRoseSelected(color.texture);
    setColorCode(color.hex);
    setBaseColorCode(color.hexbase);
  };

  const [data, setData] = useState({});

  useEffect(() => {
    setMaterial(null);
    setData(dataClient);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionRefs.findIndex(
              (ref) => ref.current === entry.target
            );
            if (index !== -1) {
              setActiveSection(index + 1);
            }
          }
        });
      },
      { root: null, threshold: 0.6 } // 60% از بخش دیده شود
    );

    sectionRefs.forEach((ref) => ref.current && observer.observe(ref.current));

    return () => {
      sectionRefs.forEach(
        (ref) => ref.current && observer.unobserve(ref.current)
      );
    };
  }, [sectionRefs, setActiveSection]);

  return (
    <>
      <div className="DesignPanel-container col-lg-12">
        <section className="DesignPanel-tools">
          <div>
            <div
              className="DesignPanel-tools-title-1"
              ref={(el) => (sectionRefs[0].current = el)}
            >
              <div className="DesignPanel-tools-title-1-line"></div>
              <h3 className="">Your Rose</h3>
              <div className="DesignPanel-tools-title-1-line"></div>
            </div>

            <div className="DesignPanel-tools-typeRose">
              <div className="DesignPanel-tools-typeRose-container">
                <div className="DesignPanel-tools-typeRose-title">
                  <span>Choose Type</span>
                </div>

                <div className="DesignPanel-tools-typeRose-content">
                  <section className="Rose-type ">
                    {data.design?.typeRose.map((type) => {
                      return (
                        <div
                          className="Rose-type-item"
                          key={type.id}
                          onClick={() => {
                            setRoseType(type.item);
                          }}
                          style={{
                            border: `1px solid ${
                              roseType == type.item ? "#5C5E62" : "#BFBFBF"
                            }`,
                            backgroundColor:
                              roseType == type.item ? "#F8E8E9" : "#ffffff",
                          }}
                        >
                          <div
                            className={`Rose-type-item-image ${
                              roseType == type.item ? "ActiveRose" : ""
                            }`}
                          >
                            <img src={type.image} alt="rose3" />
                          </div>
                          <span
                            className="Rose-type-item-text"
                            style={{
                              fontWeight: roseType == type.item ? "700" : "400",
                              color:
                                roseType == type.item ? "#000000" : "#5C5E62",
                            }}
                          >
                            {type.title}
                          </span>
                        </div>
                      );
                    })}
                  </section>
                </div>
              </div>
            </div>

            <div className="DesignPanel-tools-colorRose">
              <div className="DesignPanel-tools-colorRose-container">
                <div className="DesignPanel-tools-colorRose-title">
                  <span>Choose Color</span>
                </div>

                <div className="DesignPanel-tools-colorRose-content">
                  <section className="Rose-color">
                    {data.design?.colorRose.map((color) => (
                      <RoseColor
                        key={color.code}
                        color={color}
                        isActive={roseColor === color.code}
                        onClick={() => handleRoseColorChange(color)}
                      />
                    ))}
                  </section>
                </div>
              </div>
            </div>

            <div className="DesignPanel-tools-title-2">
              <div className="DesignPanel-tools-title-2-line"></div>
              <h3 className="">Design Your Rose</h3>
              <div className="DesignPanel-tools-title-2-line"></div>
            </div>
            <div ref={(el) => (sectionRefs[1].current = el)}>
              <DesignRose />
            </div>

            <div className="DesignPanel-tools-title-3">
              <div className="DesignPanel-tools-title-3-line"></div>
              <h3>Packaging</h3>
              <div className="DesignPanel-tools-title-3-line"></div>
            </div>

            <div ref={(el) => (sectionRefs[2].current = el)}>
              <Packaging />
            </div>
          </div>
        </section>

        <section className="DesignPanel-price">
          <Price />
        </section>

        <DesignPanelToggler />
      </div>
    </>
  );
}
