import { useContext, useEffect, useRef, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import icon7 from "../../../../../../../assets/icon7.png";

export default function AddImage() {
  const { setDesignPanelMenu, image, setImage } = useContext(HomeContext);

  return (
    <>
      <div className="AddImage">
        <div className="AddImage-container">
          <div className="AddImage-title">
            <span>Add Image</span>
          </div>
          <div className="AddImage-content">
            <div className="ImageTab">
              {image ? (
                <div className="ImageTab-view">
                  <img src={image} alt="image" />
                  <div
                    className="ImageTab-view-close"
                    onClick={() => {
                      setImage(null);
                    }}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                </div>
              ) : (
                <div
                  className="ImageTab-drag"
                  onClick={() => {
                    setDesignPanelMenu(21);
                  }}
                >
                  <img src={icon7} alt="image1" />
                  <button className="ImageTab-drag-browseBtn">
                    Brows Files
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
