import { useContext, useEffect, useRef, useState } from "react";
import icon2 from "../../../../assets/icon2.png";
import icon3 from "../../../../assets/icon3.png";
import icon4 from "../../../../assets/icon4.png";
import icon5 from "../../../../assets/icon5.png";
import icon51 from "../../../../assets/icon5+.png";
import icon6 from "../../../../assets/icon6.png";
import { HomeContext } from "../../Context/HomeContext";

export default function DesignPanelToggler() {
  const { designPanelMenu, activeSection, setActiveSection, sectionRefs } =
    useContext(HomeContext);

  const handleScrollTo = (index) => {
    const section = sectionRefs[index].current;
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
      setActiveSection(index + 1);
    }
  };
  //   if (designPanelMenu === 1) {
  //     item1 = "PanelToggler-item-icon ActiveI";
  //     item2 = "PanelToggler-item-icon FutureI";
  //     item3 = "PanelToggler-item-icon FutureI";
  //     item4 = "PanelToggler-item-icon FutureI";
  //     divider1 = "FutureD";
  //     divider2 = "FutureD";
  //     divider3 = "FutureD";
  //   } else if (designPanelMenu === 2) {
  //     item1 = "PanelToggler-item-icon PastI";
  //     item2 = "PanelToggler-item-icon ActiveI";
  //     item3 = "PanelToggler-item-icon FutureI";
  //     item4 = "PanelToggler-item-icon FutureI";
  //     divider1 = "PastD";
  //     divider2 = "FutureD";
  //     divider3 = "FutureD";
  //   } else if (
  //     designPanelMenu === 3 ||
  //     designPanelMenu === 31 ||
  //     designPanelMenu === 32 ||
  //     designPanelMenu === 33 ||
  //     designPanelMenu === 321 ||
  //     designPanelMenu === 322
  //   ) {
  //     item1 = "PanelToggler-item-icon PastI";
  //     item2 = "PanelToggler-item-icon PastI";
  //     item3 = "PanelToggler-item-icon ActiveI";
  //     item4 = "PanelToggler-item-icon FutureI";
  //     divider1 = "PastD";
  //     divider2 = "PastD";
  //     divider3 = "FutureD";
  //   } else if (designPanelMenu === 4) {
  //     item1 = "PanelToggler-item-icon PastI";
  //     item2 = "PanelToggler-item-icon PastI";
  //     item3 = "PanelToggler-item-icon PastI";
  //     item4 = "PanelToggler-item-icon ActiveI";
  //     divider1 = "PastD";
  //     divider2 = "PastD";
  //     divider3 = "PastD";
  //   } else {
  //     item1 = "PanelToggler-item-icon FutureI";
  //     item2 = "PanelToggler-item-icon FutureI";
  //     item3 = "PanelToggler-item-icon FutureI";
  //     item4 = "PanelToggler-item-icon FutureI";
  //     divider1 = "FutureD";
  //     divider2 = "FutureD";
  //     divider3 = "FutureD";
  //   }
  // };
  // handleStyle();

  // useEffect(() => {
  //   handleStyle();
  // }, [designPanelMenu]);

  return (
    <>
      <div className="PanelToggler">
        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            <img
              src={icon6}
              alt="pointer"
              style={{
                display: activeSection === 1 ? "inline" : "none",
              }}
            />
          </div>

          <div
            className={`PanelToggler-item-icon ${
              activeSection === 1
                ? "ActiveI"
                : activeSection > 1
                ? "PastI"
                : "FutureI"
            }`}
            onClick={() => handleScrollTo(0)}
          >
            <img src={icon2} alt="Choose Type" width="24px" height="24px" />
          </div>
          <div className="PanelToggler-item-text">Your Rose</div>
        </section>

        {/* <div className="PanelToggler-divider">
          <div className={divider1}></div>
        </div> */}

        {/* <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {designPanelMenu === 2 && <img src={icon6} alt="pointer" />}
          </div>
          <div
            className={item2}
            onClick={() => {
              setDesignPanelMenu(2);
              localStorage.setItem("designPanelMenu1", 2);
            }}
          >
            <img src={icon3} alt="Choose Color" width="18px" height="18px" />
          </div>
          <div
            className="PanelToggler-item-text"
            style={{
              fontWeight: designPanelMenu === 2 ? "900" : "700",
            }}
          >
            Choose Color
          </div>
        </section> */}

        <div className="PanelToggler-divider">
          <div className={activeSection >= 2 ? "PastD" : "FutureD"}></div>
        </div>

        <section className="PanelToggler-item">
          <div
            className="PanelToggler-item-pointer"
            style={{
              right: "5px",
            }}
          >
            {activeSection === 2 ? <img src={icon6} alt="pointer" /> : null}
          </div>
          <div
            className={`PanelToggler-item-icon ${
              activeSection === 2
                ? "ActiveI"
                : activeSection > 2
                ? "PastI"
                : "FutureI"
            }`}
            onClick={() => handleScrollTo(1)}
          >
            <img src={icon4} alt="Design Rose" width="18px" height="18px" />
          </div>
          <div className="PanelToggler-item-text">Design Your Rose</div>
        </section>

        <div className="PanelToggler-divider">
          <div className={activeSection >= 3 ? "PastD" : "FutureD"}></div>
        </div>

        <section className="PanelToggler-item">
          <div className="PanelToggler-item-pointer">
            {activeSection === 3 && <img src={icon6} alt="pointer" />}
          </div>
          <div
            className={`PanelToggler-item-icon ${
              activeSection === 3 ? "ActiveI" : "FutureI"
            }`}
            onClick={() => handleScrollTo(2)}
          >
            {activeSection === 3 ? (
              <img src={icon51} alt="Packaging" width="20px" height="20px" />
            ) : (
              <img src={icon5} alt="Packaging" width="20px" height="20px" />
            )}
          </div>
          <div className="PanelToggler-item-text">Packaging</div>
        </section>
      </div>
    </>
  );
}
