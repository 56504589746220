import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import dataClient from "../../../../../../../assets/Json/dataClient.json";
import tick from "../../../../../../../assets/Tick.png";

export default function Material() {
  const { material, setMaterial } = useContext(HomeContext);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(dataClient);
  }, []);

  return (
    <>
      <div className="Rose-material">
        <div className="Rose-material-title">
          <span>Choose Material</span>
        </div>
        <div className="Rose-material-list">
          {data.design?.packaging?.material.map((m) => {
            return (
              <div
                className="Rose-material-list-item"
                key={m.id}
                style={{
                  border:
                    material === m.title
                      ? "1px solid #000000"
                      : "0.5px solid #BFBFBF",
                  backgroundColor: material === m.title ? "#F8E8E9" : "#ffffff",
                }}
                onClick={() => {
                  setMaterial(m.title);
                }}
              >
                <div className="Tick">
                  <img
                    src={tick}
                    alt="tick"
                    style={{
                      display: material === m.title ? "inline" : "none",
                    }}
                  />
                </div>
                <span
                  style={{
                    fontWeight: material === m.title ? "700" : "400",
                    color: material === m.title ? "#000000" : "#5C5E62",
                  }}
                >
                  {m.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
