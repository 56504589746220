import { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../../../Context/HomeContext";
import left from "../../../../../../../assets/left.png";
import Slider from "react-slick";
import dataClient from "../../../../../../../assets/Json/dataClient.json";

export default function AddText() {
  const {
    fontFamily,
    setFontFamily,
    text,
    setText,
    setFontFamilyPath,
    selectedColor,
    setSelectedColor,
  } = useContext(HomeContext);

  let colorList = dataClient?.design?.text?.textColor;
  let fontList = dataClient?.design?.text?.textFont;

  const handleTextChange = (e) => {
    console.log("ABC handleTextChange e.target.id",e.target.id)
    if (e.target.value.length <= 14) {
      if (e.target.id === "line1") {
        setText({ ...text, line1: e.target.value });
      } else if (e.target.id === "line2") {
        setText({ ...text, line2: e.target.value });
      } else if (e.target.id === "line3") {
        setText({ ...text, line3: e.target.value });
      } else if (e.target.id === "line4") {
        setText({ ...text, line4: e.target.value });
      } else {
        console.log("Error");
      }
      console.log(text);
    }
  };

  const CustomNextArrow2 = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow next" onClick={onClick}>
        <i className="bi bi-chevron-right"></i>
      </div>
    );
  };

  const CustomPrevArrow2 = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow prev" onClick={onClick}>
        <i className="bi bi-chevron-left"></i>
      </div>
    );
  };

  const [currentSlide2, setCurrentSlide2] = useState(0);

  const handleColorSelect = (color) => {
    setSelectedColor(color.hex);
  };

  let settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7.5,
    slidesToScroll: 8,
    nextArrow:
      currentSlide2 >= colorList?.length - 7.5 ? null : <CustomNextArrow2 />,
    prevArrow: currentSlide2 === 0 ? null : <CustomPrevArrow2 />,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide2(newIndex),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 8,
          nextArrow:
            currentSlide2 >= colorList?.length - 10 ? null : (
              <CustomNextArrow2 />
            ),
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 9.5,
          slidesToScroll: 8,
          nextArrow:
            currentSlide2 >= colorList?.length - 9.5 ? null : (
              <CustomNextArrow2 />
            ),
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 7,
          nextArrow:
            currentSlide2 >= colorList?.length - 8 ? null : (
              <CustomNextArrow2 />
            ),
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 6,
          nextArrow:
            currentSlide2 >= colorList?.length - 7 ? null : (
              <CustomNextArrow2 />
            ),
          dots: false,
          infinite: false,
        },
      },
    ],
  };

  const textAreaItem = ["line1", "line2", "line3", "line4"];

  function TextArea({ line }) {
    return (
      <div className="New-text-item" key={line}>
        <div className="New-text-item-title">{line}: </div>
        <textarea
          id={line}
          className="New-text-item-textarea"
          style={{
            fontFamily,
            color: selectedColor,
            backgroundColor:
              selectedColor === "#FFFFFF" ? "#808080" : "#ffffff",
          }}
          placeholder="Only 15 character"
          maxLength={15}
          value={text.line}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          wrap="hard"
        />
      </div>
    );
  }

  const handleKeyDown = (e) => {
    // جلوگیری از زدن Enter
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="AddText">
        <div className="AddText-container">
          <div className="New">
            <section className="New-text">
              {textAreaItem?.map((line) => {
                return TextArea({ line });
              })}
            </section>

            <section className="New-textFont">
              <label>Font Type</label>
              <select
                value={fontFamily}
                onChange={(e) => {
                  setFontFamilyPath(`/CustomizerFont/${e.target.value}.ttf`);
                  setFontFamily(e.target.value);
                }}
              >
                <option value="Roboto" style={{ fontFamily: "Roboto" }}>
                  Roboto
                </option>
                {fontList?.map((font, index) => {
                  return (
                    <option key={index} value={font.name} style={{ fontFamily: font.name }}>
                      {font.name}
                    </option>
                  );
                })}
              </select>
            </section>

            <section className="New-textColor">
              <label>Font color</label>
              <div className="New-textColor-list">
                {colorList?.map((color, index) => (
                  <div
                    key={index}
                    className="New-textColor-list-item"
                    style={{
                      border: `${
                        selectedColor === color.hex ? "2px" : "1px"
                      } solid ${
                        selectedColor === color.hex ? "#000000" : "#BFBFBF"
                      }`,
                    }}
                    onClick={() => handleColorSelect(color)}
                  >
                    <div
                      style={{
                        backgroundColor: color.hex,
                      }}
                    />
                    <i
                      className="bi bi-check-lg New-textColor-list-item-check"
                      style={{
                        color: color.check,
                        display: selectedColor === color.hex ? "block" : "none",
                      }}
                    ></i>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
