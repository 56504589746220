import { useContext, useRef, useState } from "react";
import { HomeContext } from "../../../../../../Context/HomeContext";

export default function ImageUpload() {
  const { setDesignPanelMenu, image, setImage } = useContext(HomeContext);

  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const cropBox = { width: 300, height: 300 }; // سایز کراپ

  const scaleStep = 0.1;
  const maxScale = 2;
  const minScale = 0.5;

  const handleZoomIn = () => {
    if (scale < maxScale) {
      setScale((prevScale) => Math.min(prevScale + scaleStep, maxScale));
    }
  };

  const handleZoomOut = () => {
    if (scale > minScale) {
      setScale((prevScale) => Math.max(prevScale - scaleStep, minScale));
    }
  };

  const progressPercentage = ((scale - minScale) / (maxScale - minScale)) * 100;

  // Start drag
  const handleDragStart = (e) => {
    setDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // Dragging
  const handleDragging = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - startPos.x,
      y: e.clientY - startPos.y,
    });
  };

  // End drag
  const handleDragEnd = () => {
    setDragging(false);
  };

  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  const cropImage = () => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = cropBox.width;
      canvas.height = cropBox.height;

      const ctx = canvas.getContext("2d");

      // محاسبه مقیاس و مکان کراپ
      const sx = -position.x / scale;
      const sy = -position.y / scale;
      const sWidth = cropBox.width / scale;
      const sHeight = cropBox.height / scale;

      ctx.drawImage(
        img,
        sx,
        sy,
        sWidth,
        sHeight,
        0,
        0,
        cropBox.width,
        cropBox.height
      );

      // تبدیل تصویر کراپ‌شده به داده Base64
      const croppedImage = canvas.toDataURL("image/png");
      setImage(croppedImage);
    };
  };

  return (
    <>
      <div className="ImageUpload">
        <div className="ImageUpload-title">
          <span>Upload Image</span>
        </div>

        <div className="ImageUpload-container">
          <div>
            <div className="ImageUpload-view">
              <div
                className="ImageUpload-view-img"
                onMouseDown={handleDragStart}
                onMouseMove={handleDragging}
                onMouseUp={handleDragEnd}
                onMouseLeave={handleDragEnd}
                style={{
                  cursor: dragging ? "grabbing" : "grab",
                }}
              >
                <img
                  src={image}
                  alt=""
                  style={{
                    transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                  }}
                />
              </div>
              <div className="ImageUpload-view-editHandler">
                <button onClick={handleZoomOut} disabled={scale <= minScale}>
                  <i className="bi bi-dash-lg"></i>
                </button>
                <div className="progress-bar-container">
                  <div
                    className="progress-bar"
                    style={{ width: `${progressPercentage}%` }}
                  ></div>
                </div>
                <button onClick={handleZoomIn} disabled={scale >= maxScale}>
                  <i className="bi bi-plus-lg"></i>
                </button>
              </div>
            </div>
            <div className="ImageUpload-edit">
              <div className="ImageUpload-edit-tryAgain" onClick={handleClick}>
                <p>Try another Picture</p>
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
        </div>

        <button
          className="ImageUpload-confirmBtn"
          onClick={() => {
            setDesignPanelMenu(2);
            cropImage();
          }}
        >
          Confirm
        </button>
      </div>
    </>
  );
}
