import { createContext, useEffect, useState } from "react";

export const HomeContext = createContext();

export default function HomeContextProvider(props) {
  const colors = [
    {
      hex: "#000000",
      check: "#FFFFFF",
    },
    {
      hex: "#393C41",
      check: "#FFFFFF",
    },
    {
      hex: "#9C9C9C",
      check: "#000000",
    },
    {
      hex: "#FFFFFF",
      check: "#000000",
    },
    {
      hex: "#384B70",
      check: "#FFFFFF",
    },
    {
      hex: "#507687",
      check: "#FFFFFF",
    },
    {
      hex: "#FCFAEE",
      check: "#000000",
    },
    {
      hex: "#B8001F",
      check: "#FFFFFF",
    },
    {
      hex: "#A1D6B2",
      check: "#000000",
    },
    {
      hex: "#CEDF9F",
      check: "#000000",
    },
    {
      hex: "#F1F3C2",
      check: "#000000",
    },
    {
      hex: "#E8B86D",
      check: "#000000",
    },
    {
      hex: "#A28B55",
      check: "#000000",
    },
    {
      hex: "#86AB89",
      check: "#000000",
    },
    {
      hex: "#CBE2B5",
      check: "#000000",
    },
    {
      hex: "#E7FBE6",
      check: "#000000",
    },
    {
      hex: "#FF204E",
      check: "#FFFFFF",
    },
    {
      hex: "#A0153E",
      check: "#FFFFFF",
    },
    {
      hex: "#5D0E41",
      check: "#FFFFFF",
    },
    {
      hex: "#00224D",
      check: "#FFFFFF",
    },
    {
      hex: "#606C5D",
      check: "#FFFFFF",
    },
    {
      hex: "#FFF4F4",
      check: "#000000",
    },
    {
      hex: "#F7E6C4",
      check: "#000000",
    },
    {
      hex: "#F1C376",
      check: "#000000",
    },
  ];

  const [roseType, setRoseType] = useState("Short-Stem-Rose");

  const [designPanelMenu, setDesignPanelMenu] = useState(1);
  const [colorRoseSelected, setColorRoseSelected] = useState(
    "/textures/colors textures/red_02_E22202.png"
  );
  const [colorCode, setColorCode] = useState("#E22202");
  const [baseColorCode, setBaseColorCode] = useState("#E22202");

  const [fontFamily, setFontFamily] = useState("Roboto");
  const [fontFamilyPath, setFontFamilyPath] = useState(
    "/CustomizerFont/Roboto.ttf"
  );
  const [selectedColor, setSelectedColor] = useState(colors[0].hex);
  const [text, setText] = useState({
    line1: "",
    line2: "",
    line3: "",
    line4: "",
  });

  const [image, setImage] = useState(null);

  const [cliPart, setCliPart] = useState(null);
  const [category, setCategory] = useState("All");

  const [material, setMaterial] = useState();
  const [quantity, setQuantity] = useState({
    item: "1-Roses",
    number: 1,
  });

  const [boxSelected, setBoxSelected] = useState({
    id: 1,
    imageSrc: "/Boxes/ACRILICO/ECUADORIAN_CARTOON.png",
    modelUrl: "/model/new/Acrylic_box_name_edits.glb",
    quantity: 1,
  });

  const [activeSection, setActiveSection] = useState(1);
  const sectionRefs = Array(3)
    .fill(null)
    .map(() => ({ current: null })); // ایجاد رفرنس برای هر بخش

  const [loading, setLoading] = useState({});
  const [loadingMain, setLoadingMain] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem("designPanelMenu1")) {
  //     let designPanelMenu1 = Number(localStorage.getItem("designPanelMenu1"));
  //     setDesignPanelMenu(designPanelMenu1);
  //     console.log("designPanelMenu1>>", typeof designPanelMenu1);
  //     console.log("designPanelMenu>>", typeof designPanelMenu);
  //   } else {
  //     localStorage.setItem("designPanelMenu1", 31);
  //   }

  //   return () => {
  //     localStorage.removeItem("designPanelMenu1");
  //   };
  // }, []);

  const contextValue = {
    roseType,
    setRoseType,
    colorRoseSelected,
    setColorRoseSelected,
    selectedColor,
    setSelectedColor,
    colors,
    fontFamily,
    setFontFamily,
    text,
    setText,
    fontFamilyPath,
    setFontFamilyPath,
    material,
    setMaterial,
    boxSelected,
    setBoxSelected,
    loading,
    setLoading,
    loadingMain,
    setLoadingMain,
    colorCode,
    setColorCode,
    baseColorCode,
    setBaseColorCode,
    designPanelMenu,
    setDesignPanelMenu,
    image,
    setImage,
    quantity,
    setQuantity,
    category,
    setCategory,
    cliPart,
    setCliPart,
    activeSection,
    setActiveSection,
    sectionRefs,
  };
  return (
    <HomeContext.Provider value={contextValue}>
      {props.children}
    </HomeContext.Provider>
  );
}
