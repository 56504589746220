import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../assets/logo.png";
import chat from "../../../../assets/chat.png";
import * as THREE from "three";
import { RoomEnvironment } from "three/addons/environments/RoomEnvironment.js";
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';  // Import RGBELoader
import dataClient from "../../../../assets/Json/dataClient.json";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { HomeContext } from "../../Context/HomeContext";
import { normalize } from "three/src/math/MathUtils";


export default function CanvasPart() {
  const canvasRef = useRef();
  const modelRef = useRef([]);
  const sceneRef = useRef();
  const cameraRef = useRef();
  const orbitRef = useRef();
  const rendererRef = useRef();
  const firstLoad = useRef(true);
  const [loadModel, setLoadModel] = useState(false);

  let newBaseColorTexture;
  let newNormalMapTexture, newNormalMapBaseRose;
  let newHeightMapTexture;
  let newRoughnessMapTexture;

  const zoomStep = 0.2;
  const minZoomDistance = 0.1;
  const maxZoomDistance = 30;

  const {
    colorCode,
    baseColorCode,
    colorRoseSelected,
    text,
    fontFamilyPath,
    fontFamily,
    selectedColor,
    loadingMain,
    setLoadingMain,
    roseType,
    material,
    quantity,
    boxSelected,
    image,
    cliPart,
  } = useContext(HomeContext);

  let mainOption;

  const logCameraPosition = () => {
    if (cameraRef.current) {
      const { x, y, z } = cameraRef.current.position;

    }
  };


  window.addEventListener('keydown', (e) => {
    // if (e.key === 'l') { // Press 'L' to log the camera's state
    //   console.log('Camera Position:', cameraRef.current.position.toArray());
    //   console.log('Camera Quaternion:', cameraRef.current.quaternion.toArray());
    //   console.log('Camera Rotation (Euler):', cameraRef.current.rotation.toArray());
    // }
  });



  // function waitForFontToLoad(fontFamily) {
  //   return new Promise((resolve) => {
  //     document.fonts.load(`10pt ${fontFamily}`).then(resolve);
  //   });
  // }

  function waitForFontToLoad(fontFamily) {
    return new Promise((resolve, reject) => {
      document.fonts
        .load(`10pt ${fontFamily}`)
        .then(() => {
          console.log("Font successfully loaded:", fontFamily);
          resolve();
        })
        .catch((error) => {
          console.error("Error loading font:", error);
          reject(error);
        });
    });
  }


  const loadText = (mainText) => {
    console.log("ABC loadText", mainText)

    const lines = [mainText.line1, mainText.line2, mainText.line3, mainText.line4];
    const petalMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];
    modelRef.current.traverse((child) => {
      if (child instanceof THREE.Mesh && child.name.includes("print_zone_logo")) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });


    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
        // تنظیم اندازه‌ی canvas برای بافت
    canvas.width = 512;
    canvas.height = 512;
    // معکوس کردن محور Y
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(0, canvas.height); // انتقال نقطه شروع به پایین Canvas
    ctx.scale(1, -1); // وارونگی محور Y (بدون آینه‌ای کردن)

    const calculateFontSize = (text) => {
      if (text.length <= 5) return 150; // Default size for short text
      if (text.length <= 10) return 100; // Medium text
      if (text.length <= 15) return 40; // Longer text
      return 60; // Extra long text
    };

    // استایل متن
    // const fontPath = fontFamily || "Arial"; // Use default font if path is not provided
    ctx.fillStyle = selectedColor || "black"; // Use default color if not selected
    // const fontSize = 140;
    // ctx.font = `${fontSize}px ${fontPath}`;
    // ctx.fillStyle = 'black'; // رنگ متن
    // ctx.font = '140px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.direction = 'rtl';
    // نوشتن خطوط متن
    // lines.forEach((line, index) => {
    //   if (line) {
    //     ctx.fillText(line, canvas.width / 2, 100 + index * 80); // هر خط کمی پایین‌تر از خط قبلی
    //   }
    // });

    const lineHeight = 90; // Adjust as needed
    const totalHeight = lines.filter(Boolean).length * lineHeight; // Total height of the text block
    const startY = (canvas.height - totalHeight) / 2 + lineHeight / 2;

    // lines.forEach((line, index) => {
    //   if (line) {
    //     ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
    //   }
    // });

    lines.forEach((line, index) => {
      if (line) {
        const fontSize = calculateFontSize(line);
        ctx.font = `${fontSize}px ${fontFamily || "Arial"}`;
        ctx.fillStyle = selectedColor || "black";
        ctx.fillText(line, canvas.width / 2, startY + index * lineHeight);
      }
    });

    // ساخت بافت از canvas
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    if (petalMesh) {

      if (petalMesh.material.map) {
        petalMesh.material.map.dispose();
      }
      petalMesh.material.map = null

      petalMesh.material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      });

      petalMesh.material.needsUpdate = true;
    } else {

      petalMeshes.forEach((petalMesh) => {
        // Remove the previous texture if exists
        if (petalMesh.material.map) {
          petalMesh.material.map.dispose();
          petalMesh.material.map = null;
        }

        // Apply the new texture
        petalMesh.material.map = null;
        petalMesh.material = new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
        });

        petalMesh.material.needsUpdate = true;
      });

    }


  }
  
  

 
  
  

  function updateMeshBaseColorTexture(
    baseColorTexturePath,
    colorCode,
    baseColorCode
  ) {
    const targetMeshes = ["petal----"];

    const textureLoader = new THREE.TextureLoader();

    // Load the new base color texture
    const newBaseColorTexture = textureLoader.load(baseColorTexturePath, () => {
      newBaseColorTexture.flipY = false;
      newBaseColorTexture.needsUpdate = true; // Ensure texture is updated after loading
    });

    modelRef.current.traverse((child) => {
      // if (child.isMesh && targetMeshes.includes(child.name)) {
      if (child.isMesh) {

        console.log('child>>>', child)
        const material = child.material;

        if (material) {
          let color;
          if (child.name.includes("petal") || child.name.includes("Petal") && !child.name.includes("print_zone") && !child.name.includes("textlayer")) {
            color = colorCode.startsWith("#")
              ? colorCode
              : "#" + colorCode;
            material.color.set(color);
            material.map = newBaseColorTexture;
            material.normalmap = newNormalMapTexture;
            material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
          if (child.name.includes("base")) {
            console.log('base ------>', child)
            const color1 = baseColorCode.startsWith("#")
              ? baseColorCode
              : "#" + baseColorCode;
            material.color.set(color1);

            material.map = null
            // material.normalmap = newNormalMapBaseRose;
            // material.roughnessMap = newRoughnessMapTexture;
            material.needsUpdate = true;
          }
        }
      }
    });
  }

  const init = () => {
    // Create scene

    const textureLoader = new THREE.TextureLoader();

    // Load your textures (update paths to your textures)
    newBaseColorTexture = textureLoader.load(
      "/textures/principal textures/red_02_E22202.png"
    );
    newNormalMapBaseRose = textureLoader.load(
      "/textures/principal textures/normal_base rosa.png"
    );
    newNormalMapTexture = textureLoader.load(
      "/textures/principal textures/Petal_Rose_Red_Normal.png"
    );
    newRoughnessMapTexture = textureLoader.load(
      "/textures/principal textures/rose_roughness.png"
    );

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Set up camera
    const camera = new THREE.PerspectiveCamera(
      45,
      canvasRef.current.clientWidth / canvasRef.current.clientHeight,
      0.01,
      1000
    );




    camera.position.set(
      0.31817526080789105,
      0.039400304623192675,
      -0.02908931569126374
    );


    camera.quaternion.set(-0.04133254261349739,
      0.7372057012213301,
      0.04528377071470179,
      0.6728809368066008);


    camera.rotation.set(
      -2.206768450613856,
      1.418069687034873,
      2.2123726704585045,
    );




    camera.lookAt(0, 0, 0)
    cameraRef.current = camera;

    // Set up renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setClearColor(0x000000, 0); // Make the background transparent
    renderer.setSize(
      canvasRef.current.clientWidth,
      canvasRef.current.clientHeight
    );

    // renderer.toneMapping = THREE.ACESFilmicToneMapping;
    // renderer.toneMapping = THREE.NeutralToneMapping;
    renderer.toneMapping = THREE.LinearToneMapping;
    renderer.toneMappingExposure = 1;

    rendererRef.current = renderer;
    canvasRef.current.appendChild(renderer.domElement);

    // Set up OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    // controls.autoRotate = true
    controls.dampingFactor = 0.05;
    controls.enableZoom = true;
    // controls.minDistance = 0.15; // Set min zoom
    // controls.maxDistance = 2; // Set max zoom
    orbitRef.current = controls;

    controls.addEventListener("change", logCameraPosition);

    const size = 10;
    const divisions = 10;

    const gridHelper = new THREE.GridHelper(size, divisions);
    // sceneRef.current.add(gridHelper);

    const loaderhdr = new RGBELoader();

    console.log(dataClient.lightSetting.environmentIntensity2, "-------")
    loaderhdr.load(dataClient.lightSetting.hdriFile2, function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      sceneRef.current.environment = texture;  // Set the environment for the scene
      sceneRef.current.environmentIntensity = dataClient.lightSetting.environmentIntensity2;  // Set the environment for the scene

    });



    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.68);
    // directionalLight.castShadow = true;
    directionalLight.position.set(0, 10, 7);
    sceneRef.current.add(directionalLight);


    setLoadingMain(true);

    // Load the GLTF model
    const loader = new GLTFLoader();
    loader.load(
      dataClient.design.typeRose[0].modelurl,
      (gltf) => {
        console.log("gltf", gltf.scene);
        const model = gltf.scene;
        model.position.set(0, 0, 0);
        sceneRef.current.add(model);
        modelRef.current = model;
        setLoadModel(true);
      },
      (xhr) => {
        // Optionally, you can monitor loading progress here
        console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        setLoadingMain(false);
      },
      (error) => {
        console.error("An error happened", error);
      }
    );

    // Lighting
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 5, 5);
    sceneRef.current.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    sceneRef.current.add(ambientLight);

    // Animate function
    const animate = () => {
      requestAnimationFrame(animate);
      orbitRef.current.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      cameraRef.current.aspect =
        canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(
        canvasRef.current.clientWidth,
        canvasRef.current.clientHeight
      );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (rendererRef.current) rendererRef.current.dispose();
    };
  };


  function clearGLBModel() {
    sceneRef.current.traverse((object) => {
      if (object.isMesh) {
        // Dispose geometry
        if (object.geometry) object.geometry.dispose();

        // Dispose materials
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach((material) => {
              disposeMaterial(material);
            });
          } else {
            disposeMaterial(object.material);
          }
        }
      }
    });

    // Remove all children from the scene
    while (sceneRef.current.children.length > 0) {
      const child = sceneRef.current.children[0];
      sceneRef.current.remove(child);
    }

    // console.log("Scene cleared!");
  }

  function loadGLBfile(url, n) {
    const loader = new GLTFLoader();

    // Load the GLB file
    loader.load(url, (gltf) => {
      modelRef.current = gltf.scene;
      sceneRef.current.add(gltf.scene);

      if (colorRoseSelected) {
        updateMeshBaseColorTexture(colorRoseSelected, colorCode, baseColorCode);
      }

      if (loadModel) {
        loadText(text);

      }

      console.log('Model added to scene!', gltf.scene);
      if (n === 3) {


        const glassMaterial = new THREE.MeshPhysicalMaterial({
          color: 0xffffff,             // Base color
          roughness: 0,                // No roughness for clear glass
          transmission: 1,             // Enables transparency (1 = fully transparent)
          thickness: 0.001,              // Physical thickness of the object
          ior: 1.3,                    // Index of Refraction (glass = ~1.5)
          specularIntensity: 1,        // Specular highlights
          specularColor: 0xffffff,     // Color of specular highlights
          envMapIntensity: 0.8,          // Intensity of reflections
          metalness: 0,                // Non-metallic material
          clearcoat: 1,                // Add clearcoat for shine
          clearcoatRoughness: 0        // Keep clearcoat smooth
        });

        sceneRef.current.traverse(object => {
          if (object.isMesh && object.name.includes('Acrylic')) {
            console.log('object acrlic >>>', object)
            object.material = glassMaterial; // Assign the material
          }
        })

      }


    });
  }

  // Helper to dispose of a material
  function disposeMaterial(material) {
    // Dispose of textures
    for (const key in material) {
      if (material[key] && material[key].isTexture) {
        material[key].dispose();
      }
    }
    material.dispose();
  }

  // const loadImageInRectangle = (imagePath, rectangleConfig) => {
  //   const { x, y, width, height } = rectangleConfig; // Rectangle config for image placement
  //   const targetMesh = modelRef.current.getObjectByName("textlayer");

  //   const loader = new THREE.TextureLoader();
  //   loader.load(
  //     imagePath,
  //     (texture) => {
  //       const canvas = document.createElement("canvas");
  //       canvas.width = 1024; // Adjust canvas size as needed
  //       canvas.height = 1024; // Adjust canvas size as needed
  //       const ctx = canvas.getContext("2d");
  //       ctx.clearRect(0, 0, canvas.width, canvas.height);
  //       ctx.fillRect(0, 0, canvas.width, canvas.height);

  //       // Draw the image within the specified rectangle
  //       const img = new Image();
  //       img.src = imagePath;
  //       img.onload = () => {
  //         ctx.drawImage(img, x, y, width, height);

  //         const canvasTexture = new THREE.CanvasTexture(canvas);
  //         canvasTexture.needsUpdate = true;

  //         if (targetMesh) {

  //           const material = new THREE.MeshStandardMaterial({
  //             map: canvasTexture,
  //             alphaMap: canvasTexture,     // استفاده از کانال آلفا برای شفافیت
  //             transparent: true,   // برای شفاف بودن
  //             alphaTest: 0.5,      // هر پیکسل با شفافیت پایین‌تر از 0.5 نادیده گرفته می‌شود
  //             opacity: 1.0         // مقدار شفافیت برای کل شیء
  //           });
  //           targetMesh.material.map = null
  //           targetMesh.material = material

  //         }
  //       };
  //     },
  //     undefined,
  //     (error) => {
  //       console.error("Error loading image:", error);
  //     }
  //   );
  // };


  const loadImageInRectangle = (imagePath, rectangleConfig) => {
    const { x, y, width, height } = rectangleConfig; // Rectangle config for image placement
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const loader = new THREE.TextureLoader();
    loader.load(
      imagePath, // Load the image from the path
      (texture) => {
        // Once the texture is loaded, we create a canvas
        const canvas = document.createElement("canvas");
        canvas.width = 1024; // Adjust canvas size as needed
        canvas.height = 1024; // Adjust canvas size as needed
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas without adding a background color
        ctx.translate(0, canvas.height); // انتقال نقطه شروع به پایین Canvas
        ctx.scale(1, -1); // وارونگی محور Y (بدون آینه‌ای کردن)

        // Draw the loaded image onto the canvas in the specified rectangle area
        const img = new Image();
        img.src = imagePath;
        img.onload = () => {
          ctx.drawImage(img, x, y, width, height); // Draw image in the rectangle

          // Create a canvas texture
          const canvasTexture = new THREE.CanvasTexture(canvas);
          canvasTexture.needsUpdate = true;

          // Apply sRGB encoding for proper gamma correction
          canvasTexture.colorSpace = THREE.SRGBColorSpace

          if (targetMesh) {

            if (targetMesh.material.map) {
              targetMesh.material.map.dispose(); // Dispose of the previous texture to free memory
            }


            targetMesh.material.map = null
            // Use the canvasTexture for the mesh material
            const material = new THREE.MeshStandardMaterial({
              map: canvasTexture, // Use canvas texture for material
              transparent: true,   // Make the material transparent
              opacity: 1.0         // Full opacity for the image
            });

            // Assign the material to the target mesh
            targetMesh.material = material;
            targetMesh.material.needsUpdate = true;

          }
        };
      },
      undefined,
      (error) => {
        console.error("Error loading image:", error);
      }
    );
  };

  const loadImage = (imagePath) => {
    const loader = new THREE.TextureLoader();
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];
    modelRef.current.traverse((child) => {
      if (child instanceof THREE.Mesh && child.name.includes("print_zone_logo")) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });
    loader.load(
      imagePath,
      (texture) => {
        if (targetMesh) {
          // Remove any previous content (text or clipart)
          // clearMeshContent();

          // Apply the image texture
          targetMesh.material = new THREE.MeshBasicMaterial({
            map: texture,
            transparent: true, // Enable transparency for PNGs
          });
          targetMesh.material.needsUpdate = true;
        } else {

          petalMeshes.forEach((petalMesh) => {
            // Remove the previous texture if exists
            if (petalMesh.material.map) {
              petalMesh.material.map.dispose();
              petalMesh.material.map = null;
            }

            // Apply the new texture
            petalMesh.material.map = null;
            petalMesh.material = new THREE.MeshBasicMaterial({
              map: texture,
              transparent: true,
            });

            petalMesh.material.needsUpdate = true;
          });
        }
      },
      undefined,
      (error) => {
        console.error('Error loading image:', error);
      }
    );
  };

  const clearMeshContent = () => {
    const targetMesh = modelRef.current.getObjectByName("textlayer");

    const petalMeshes = [];
    modelRef.current.traverse((child) => {
      if (child instanceof THREE.Mesh && child.name.includes("print_zone_logo")) {
        petalMeshes.push(child); // Add matching meshes to an array
      }
    });
    if (targetMesh && targetMesh.material) {
      // Dispose of the current material and texture
      if (targetMesh.material.map) {
        targetMesh.material.map.dispose();
        targetMesh.material.map = null;
      }

      // Clear the material
      targetMesh.material.dispose();
      targetMesh.material = new THREE.MeshBasicMaterial({
        color: 0xffffff, // Default blank material (white)
        transparent: true,
        opacity: 0, // Make it fully invisible
      });
      targetMesh.material.needsUpdate = true;
    } else {

      petalMeshes.forEach((petalMesh) => {
        // Remove the previous texture if exists
        if (petalMesh.material.map) {
          petalMesh.material.map.dispose();
          petalMesh.material.map = null;
        }

        // Apply the new texture
        petalMesh.material.dispose()
        petalMesh.material.map = null;
        petalMesh.material = new THREE.MeshBasicMaterial({
          color: 0xffffff, // Default blank material (white)
          transparent: true,
          opacity: 0,
        });

        petalMesh.material.needsUpdate = true;
      });

    }
  };



  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      init(); // Call init function to set up Three.js
    }
  }, []);

  useEffect(() => {
    console.log('ABC text', text)
    console.log('ABC fontFamily', fontFamily)
    console.log('ABC selectedColor', selectedColor)
    console.log('ABC fontFamilyPath', fontFamilyPath)
    if (loadModel) {
      loadText(text);

    }
  }, [text, fontFamily, fontFamilyPath, selectedColor]);



  useEffect(() => {
    console.log('ABC image', image);
    console.log('ABC clipArt', cliPart);

    if (loadModel) {
      if (image) {
        // loadImage(image);
        loadImageInRectangle(image, {
          x: 256, // X position of the image on the canvas
          y: 256, // Y position of the image on the canvas
          width: 512, // Width of the image
          height: 512, // Height of the image
        });
      } else if (cliPart) {
        loadImageInRectangle(cliPart.file, {
          x: 256, // X position of the image on the canvas
          y: 256, // Y position of the image on the canvas
          width: 512, // Width of the image
          height: 512, // Height of the image
        });
      } else {
        // Clear the mesh if no image or clipart is provided
        // clearMeshContent();
      }
    }
  }, [image, cliPart]);


  useEffect(() => {

    // console.log('ROSE TYPE>>', roseType)
    if (firstLoad.current === false) {
      if (roseType === "Petal") {
        clearGLBModel()
        loadGLBfile(dataClient.design.typeRose[1].modelurl, 0)

        cameraRef.current.position.set(
          -4.497587249914217e-8, 0.37744515205451845, -3.747559388488653e-7
        );
      } else if (roseType === "Short-Stem-Rose") {
        clearGLBModel()
        loadGLBfile(dataClient.design.typeRose[0].modelurl, 0)

        cameraRef.current.position.set(
          0.31817526080789105,
          0.039400304623192675,
          -0.02908931569126374
        );
        cameraRef.current.quaternion.set(-0.04133254261349739,
          0.7372057012213301,
          0.04528377071470179,
          0.6728809368066008);
        cameraRef.current.rotation.set(
          -2.206768450613856,
          1.418069687034873,
          2.2123726704585045,
        );

      }
    }

  }, [roseType]);



  useEffect(() => {

    console.log('material', material)
    console.log('boxSelected', boxSelected)

    if (firstLoad.current === false) {
      if (typeof boxSelected === 'object') {

        if (material === "acrylic") {
          clearGLBModel()
          loadGLBfile(boxSelected.modelUrl, 0)

          cameraRef.current.rotation.set(-3.0153203766607066,
            -0.2492825992030517,
            -3.110283771133394,)

          cameraRef.current.quaternion.set(0.03694857480699489,
            0.9525522773718701,
            0.12877743647953666,
            -0.2733044704119469)

          cameraRef.current.position.set(
            -0.05908754078252171,
            0.07200797251336204,
            -0.25683295753968854
          )




          const loaderhdr = new RGBELoader();

          loaderhdr.load(dataClient.lightSetting.hdriFile2, function (texture) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            sceneRef.current.environment = texture;  // Set the environment for the scene
            sceneRef.current.environmentIntensity = dataClient.lightSetting.environmentIntensity2;  // Set the environment for the scene
          });
          const plan1 = new THREE.PlaneGeometry(20, 20)
          const material1 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide })
          const mesh1 = new THREE.Mesh(plan1, material1)
          mesh1.rotation.x = Math.PI / 2;
          mesh1.position.set(0, -2.5, 0);
          // sceneRef.current.add(mesh1)

          // تعریف Plane دوم که عمود است
          const geometry2 = new THREE.PlaneGeometry(20, 20);
          const material2 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
          const plane2 = new THREE.Mesh(geometry2, material2);
          // sceneRef.current.add(plane2);

          // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
          plane2.position.set(0, 2.5, 2.5); // تغییر مکان به ضلع بالای Plane اول

          // تعریف Plane دوم که عمود است
          const geometry3 = new THREE.PlaneGeometry(20, 20);
          const material3 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
          const plane3 = new THREE.Mesh(geometry3, material3);
          // sceneRef.current.add(plane3);

          // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
          plane3.position.set(0, 2.5, -2.5); // تغییر مکان به ضلع بالای Plane اول
          plane3.rotation.y = Math.PI; // چرخش 180 درجه برای پشت

          // تعریف Plane دوم که عمود است
          const geometry4 = new THREE.PlaneGeometry(20, 20);
          const material4 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
          const plane4 = new THREE.Mesh(geometry4, material4);
          // sceneRef.current.add(plane4);

          // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
          plane4.position.set(2.5, 2.5, 0); // تغییر مکان به ضلع بالای Plane اول
          plane4.rotation.y = -Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)


          // تعریف Plane دوم که عمود است
          const geometry5 = new THREE.PlaneGeometry(20, 20);
          const material5 = new THREE.MeshBasicMaterial({ color: 0xFFFAFA, side: THREE.DoubleSide });
          const plane5 = new THREE.Mesh(geometry5, material5);
          // sceneRef.current.add(plane5);

          // مکان Plane دوم را به یک ضلع Plane اول تنظیم کنید
          plane5.position.set(-2.5, 2.5, 0); // تغییر مکان به ضلع بالای Plane اول
          plane5.rotation.y = Math.PI / 2; // چرخش 90 درجه روی محور Y (عمود بر Plane اول)

        }

        else if (material === "Paper") {
          clearGLBModel()
          loadGLBfile(boxSelected.modelUrl, 0)
          const loaderhdr = new RGBELoader();

          loaderhdr.load(dataClient.lightSetting.hdriFile1, function (texture) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            sceneRef.current.environment = texture;  // Set the environment for the scene
            sceneRef.current.environmentIntensity = dataClient.lightSetting.environmentIntensity1

          });

        }
      } else {
        console.log('no box selected yet')
      }


    }

  }, [material, quantity, boxSelected]);


  useEffect(() => {
    if (colorRoseSelected && loadModel) {
      updateMeshBaseColorTexture(colorRoseSelected, colorCode, baseColorCode);
    }
  }, [colorRoseSelected]);

  const handleReset = () => {
    // alert("Reset");
    cameraRef.current.position.set(
      -0.3518734965497957,
      0.23914218048842792,
      0.0804560829307368
    );
  };

  const zoomStep2 = 1; // Adjust this value for the zoom step
  const minZoomDistance2 = 5; // Minimum zoom distance
  const maxZoomDistance2 = 50; // Maximum zoom distance

const handleZoomIn = () => {
  console.log("Zooming In:",  orbitRef.current);

  cameraRef.current.fov = Math.max(10, cameraRef.current.fov - 5); // Decrease FOV to zoom in
  cameraRef.current.updateProjectionMatrix();

};

const handleZoomOut = () => {
  console.log("Zooming Out:", cameraRef.current.position.z);

  cameraRef.current.fov = Math.min(100, cameraRef.current.fov + 5); // Increase FOV to zoom out
  cameraRef.current.updateProjectionMatrix();
  }


  // const handleZoomIn = () => {
  //   console.log('zoom in', cameraRef.current.position.z)

  //   // orbitRef.current.camera.dollyIn(1.1);
  //   // orbitRef.current.update(); 


  //   if (cameraRef.current.position.z > minZoomDistance2) {
  //     cameraRef.current.position.z -= zoomStep2; // Move the camera closer
  //     orbitRef.current.update(); // Update OrbitControls to reflect changes
  //   }
  // };

  // const handleZoomOut = () => {
  //   console.log('zoom out', cameraRef.current.position.z)

  //   if (cameraRef.current.position.z < maxZoomDistance2) {
  //     cameraRef.current.position.z += zoomStep2; // Move the camera farther
  //     orbitRef.current.update(); // Update OrbitControls to reflect changes
  //   }
  // };




  return (
    <>
      <div className="CanvasPart">
        <div className="Logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="Chat">
          <img src={chat} alt="chat" />
        </div>

        <div className="Canvas" ref={canvasRef}></div>

        <div className="CanvasPart-controler">
          <button className="CanvasPart-controler-item" onClick={handleReset}>
            <i
              className="bi bi-arrow-counterclockwise CanvasPart-controler-item-i"
              style={{
                transform: "rotate(.09turn)",
              }}
            ></i>
          </button>
          <button className="CanvasPart-controler-item" onClick={handleZoomOut}>
            <i className="bi bi-dash-lg CanvasPart-controler-item-i"></i>
          </button>
          <button className="CanvasPart-controler-item" onClick={handleZoomIn}>
            <i className="bi bi-plus-lg CanvasPart-controler-item-i"></i>
          </button>
        </div>
      </div>
    </>
  );
}
